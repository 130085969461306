import { passwordMessages } from '../password/Messages';

export const formMessages = {
    required: () => 'Required',
    email: () => 'Must be a valid e-mail',
    emailExists: () => 'E-mail already exists',
    minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
    maxLength: ({ $params }) => `Must be less than ${$params.maxLength.max} characters.`,
    passwordStrengthTest: ({ $params }) => $params.passwordStrengthTest.message,
    letters: () => 'Can includes only letters',
    isLatinLetters: () => 'Can includes only latin letters',
    nicknameExists: () => 'Nickname already exists',
    exclusions: () => 'Nickname cannot include only numbers or symbol',
    spacesNotAllowed: () => 'Spaces not allowed',
    ...passwordMessages
    // phoneValidated: () => 'Phone number is invalid'
};
