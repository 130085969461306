<template lang="html">
    <entering-form-layout>
        <div class="authentication__form">
            <!-- signing login section -->
            <div>
                <!-- signing login section -->
                <div class="signin-section form-block">
                    <div class="form-block__header">
                        <h2 class="form-block__title">
                            Register
                        </h2>
                    </div>

                    <div class="form-block__body">
                        <form-input
                            v-model.trim="nicknameValue"
                            type="text"
                            legend="Nickname"
                            name="nickname"
                            :field.sync="nicknameValue"
                            autocomplete="off"
                            :errors="validationMessage($v.nickname)"
                            :is-valid="$v.nickname.$dirty && !$v.nickname.$anyError"
                            :loading="loadNickname"
                            @input.native="$v.nickname.$touch()"
                            @onChange="loadNickname = true"
                        />
                        <form-input
                            v-model="$v.firstname.$model"
                            type="text"
                            legend="Firstname"
                            name="firstname"
                            :field.sync="firstname"
                            autocomplete="nope"
                            :errors="validationMessage($v.firstname)"
                            :is-valid="$v.firstname.$dirty && !$v.firstname.$anyError"
                            @input.native="$v.firstname.$touch();"
                            @blur="$v.firstname.$touch();"
                        />
                        <form-input
                            v-model="$v.lastname.$model"
                            type="text"
                            legend="Lastname"
                            name="lastname"
                            :field.sync="lastname"
                            :errors="validationMessage($v.lastname)"
                            :is-valid="$v.lastname.$dirty && !$v.lastname.$anyError"
                            @input.native="$v.lastname.$touch();"
                            @blur="$v.lastname.$touch();"
                        />
                        <form-input
                            v-model.trim="emailValue"
                            prepend-icon="EmailOutlineIcon"
                            type="text"
                            legend="Email"
                            name="email"
                            :field.sync="emailValue"
                            autocomplete="off"
                            :errors="validationMessage($v.email)"
                            :is-valid="$v.email.$dirty && !$v.email.$anyError"
                            :loading="loadEmail"
                            @input.native="$v.email.$touch()"
                            @onChange="loadEmail = true"
                        />
                        <div
                            v-show="$v.email.$params.emailExists"
                            class="forgot-alert"
                        >
                            <information />
                            <div class="forgot-alert__content">
                                <span class="forgot-alert__content__text">Existing users can</span>
                                <router-link
                                    :to="{ name: 'forgot-password', params: {} }"
                                    class="forgot-alert__content__link"
                                >
                                    reset password here
                                </router-link>
                            </div>
                        </div>
                        <div class="password_field">
                            <form-input
                                v-model="$v.password.$model"
                                prepend-icon="LockIcon"
                                :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                :type="showPassword ? 'text' : 'password'"
                                legend="Password"
                                name="password"
                                :maxlength="20"
                                :field.sync="password"
                                autocomplete="new-password"
                                :errors="validationMessage($v.password)"
                                :is-valid="$v.password.$dirty && !$v.password.$anyError"
                                @clicked="showPassword = !showPassword"
                                @input.native="$v.password.$touch()"
                                @blur="$v.password.$touch(); passwordInFocus = false"
                                @focus="passwordInFocus = true"
                            />
                            <div
                                v-if="passwordInFocus && $v.password.$anyError"
                                class="password-validation"
                            >
                                <password-validator-helper :password="password" />
                            </div>
                            <!-- <progress-linear-->
                            <!-- v-if="password.length > 7"-->
                            <!-- :style="{marginBottom: '30px'}"-->
                            <!-- :validate="$v.password.$params.passwordStrengthTest"-->
                            <!-- />-->
                        </div>
                        <phone-number
                            @phoneValidated="validatePhone"
                        />
                        <p class="form-block__description">
                            Back to
                            <router-link
                                class="colored-text"
                                :to="{ name: 'login', params: {} }"
                            >
                                Login
                            </router-link>
                            or
                            <router-link
                                class="colored-text"
                                :to="{
                                    name: 'forgot-password',
                                    params: {}
                                }"
                            >
                                Forgot your password?
                            </router-link>
                        </p>
                        <!-- <span>error message: {{ validationMessage($v.phoneValidated) }}</span> -->
                        <button
                            class="btn-base btn-main button-width-100"
                            :disabled="!isFormValid"
                            @click="validate"
                        >
                            <template v-if="loading">
                                <button-loader />
                            </template>
                            <span v-else>Register</span>
                        </button>
                        <!-- <social-login
                            mode="login"
                            class="my-3"
                        /> -->
                    </div>
                    <div class="form-block__footer" />
                </div>
                <!-- signing login section -->
            </div>
            <!-- signing login section -->
        </div>
    </entering-form-layout>
</template>

<script>
// event bus
import { eventBus } from '@/helpers/event-bus/'

// components
import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import ButtonLoader from '@/components/common/ButtonLoader.vue'
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';
// import SocialLogin from '@/components/common/SocialLogin.vue';

// validation
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import passwordValidationMixin from '@/mixins/passwordValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/register/Rules'
import { formMessages } from '@/validation/register/Messages'

// icons
import Information from 'mdi-vue/Information.vue'

// types
import {
    ACTION_WRITER_CHECK_EMAIL,
    ACTION_CREATE_WRITER,
    ACTION_LOGIN_WRITER,
    ACTION_WRITER_CHECK_NIKNAME
} from '@/store/modules/writer/action-types'

// vuex bindings
import {
    createNamespacedHelpers,
    mapState
} from 'vuex'

import { mapWriterFields } from '@/store/modules/writer'

import { debounce } from 'lodash'

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Register',
    components: {
        EnteringFormLayout,
        PhoneNumber,
        ButtonLoader,
        Information,
        PasswordValidatorHelper
        // SocialLogin
    },
    metaInfo: {
        title: 'Register'
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        passwordValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        showPassword: false,
        loading: false,
        phoneValidationStatus: false,
        // Email logic
        emailValue: '',
        loadEmail: false,
        // Nickname logic
        nicknameValue: '',
        loadNickname: false,
        isSuccess: false,
        passwordInFocus: false
    }),
    computed: {
        ...mapWriterFields([
            'writer_data.nickname',
            'writer_data.firstname',
            'writer_data.lastname',
            'writer_data.email',
            'writer_data.password',
            'writer_data.id'
        ]),
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        isFormValid() {
            return !this.$v.$invalid && this.verified
        }
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail
            this.loadEmail = false
        }, 150),
        nicknameValue: debounce(function (newNicknae) {
            this.nickname = newNicknae
            this.loadNickname = false
        }, 150)
    },
    mounted() {
        this.emailValue = this.email
        if (this.$route.query.loc) {
            window.localStorage.setItem('last_location', decodeURIComponent(this.$route.query.loc))
        }
        this.nicknameValue = this.nickname
        this.checkIsValid()
    },
    methods: {
        ...mapWriterActions([
            ACTION_CREATE_WRITER,
            ACTION_WRITER_CHECK_EMAIL,
            ACTION_LOGIN_WRITER,
            ACTION_WRITER_CHECK_NIKNAME
        ]),
        async checkEmail(value) {
            if (!this.isSuccess) {
                const { data } = await this[ACTION_WRITER_CHECK_EMAIL](value)
                return data
            }
            return {}
        },
        async checkNikname(value) {
            if (!this.isSuccess) {
                const { data } = await this[ACTION_WRITER_CHECK_NIKNAME](value)
                return data
            }
            return {}
        },
        validationMessage: validationMessage(formMessages),
        validatePhone(value) {
            this.phoneValidationStatus = value
        },
        validateForm() {
            return this.$v.$invalid
        },
        checkIsValid() {
            if (this.nickname || this.firstname || this.lastname || this.email || this.password || this.phone) {
                this.$v.$touch()
            }
        },
        async submit() {
            try {
                this.loading = true
                await this[ACTION_CREATE_WRITER]()
                eventBus.$emit('showSnackBar', 'Your account has been created', 'success')
                // notify google
                // vm.recordEventRegister()

                try {
                    // login client
                    await this[ACTION_LOGIN_WRITER]({
                        email: this.email,
                        password: this.password,
                        remember_me: false
                    })
                    eventBus.$emit('showSnackBar', 'You are logged in', 'success')
                    this.isSuccess = true
                    this.$router.push({
                        name: 'wizard-address'
                    })
                } catch (err) {
                    eventBus.$emit('showSnackBar', 'Cannot create account. Please contact support', 'error')
                }
            } catch (err) {
                // const errors = Object.values(err?.response?.data?.errors || {})
                // const message = errors.length ? errors.reduce((acc, item) => `${acc}${item}<br/>`, '') : (err.response.data.message || 'Server error')
                // console.error(err.response)
                eventBus.$emit('showSnackBar', err, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
