import {
    required,
    minLength,
    maxLength,
    email
} from 'vuelidate/lib/validators';
import passwordRules from '../password/Rules.js'

export const validationRules = {
    nickname: {
        required,
        minLength: minLength(3),
        async nicknameExists(value) {
            if (!this.$v.nickname.required) {
                return true;
            }
            const response = await this.checkNikname({ nickname: value })
            return response.success
        },
        spacesNotAllowed(value) {
            if (!value) {
                return false
            }
            return value.indexOf(' ') === -1
        },
        exclusions(value) {
            // At least one LOWERCASE character:
            const excludeSymbol = /^\d*[a-zA-Z][a-zA-Z\d_-]*$/;
            return excludeSymbol.test(value)
        }
    },
    firstname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        async isLatinLetters(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.firstname.required) { return true; }

            return new Promise((resolve) => {
                if (/^[a-zA-Z]+$/.test(value)) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        }
    },
    lastname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        async isLatinLetters(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.lastname.required) { return true; }

            return new Promise((resolve) => {
                if (/^[a-zA-Z]+$/.test(value)) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        }
    },
    email: {
        required,
        email,
        async emailExists(value) {
            if (!this.$v.email.required || !this.$v.email.email) {
                return true;
            }
            try {
                const response = await this.checkEmail({ email: value })
                return response.success
            } catch (error) {
                return false
            }
        }
    },
    password: { ...passwordRules }
}
